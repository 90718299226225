const distribuidorConfig : Array<any> = [
  	{
		"codDistribuidor" : 2,
		"distribuidor" : "homologacao",
		"dominio_gestao" : "desenvolvimento.acampa.app",
		"dominio_api" : "api.desenvolvimento.acampa.app",
		"dominio_integracao" : "api.desenvolvimento.acampa.app",
		"dominio_checklist" : "checklist.desenvolvimento.acampa.app",
		"dominio_orcamento" : "dev.acampa.orcamento.app",
		"dominio_pesquisa" : "dev-camp-pesquisa-api.azurewebsites.net",
		"name_empresa" : "HML",
		"link_loja_ios" : "",
		"link_loja_android" : "",
		"mascara_codigo_interno" : "000000/00",
		"mascara_codigo_login" : "###.###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https"
	},
	{
		"codDistribuidor" : 3,
		"distribuidor" : "localhost",
		"dominio_gestao" : "localhost:8080",
		"dominio_api" : "127.0.0.1:8000", 
		"dominio_integracao" : "127.0.0.1:8000",
		// "dominio_api" : "dev-acampa-api-lumen.azurewebsites.net",
		"dominio_checklist" : "localhost",
		"dominio_orcamento" : "localhost",
		"dominio_pesquisa" : "localhost",
		"name_empresa" : "Localhost",
		"link_loja_ios" : "https://apps.apple.com/br/app/interozone-e-pescan-oferta/id1581655942",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=app.interozone",
		"mascara_codigo_interno" : "000000/00",
		"mascara_codigo_login" : "###.###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "http"
	},	
	{
		"codDistribuidor" : 4,
		"distribuidor" : "atria",
		"dominio_gestao" : "acampa.app",
		"dominio_api" : "api.acampa.app",
		"dominio_integracao" : "integracao.acampa.app",
		"dominio_checklist" : "checklist.acampa.app",
		"dominio_orcamento" : "at.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.acampa.app",
		"name_empresa" : "Atria",
		"link_loja_ios" : "https://apps.apple.com/us/app/acampa-oferta-digital-atria/id1451542707",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=br.com.atrialub.acampa",
		"mascara_codigo_interno" : "000000/00",
		"mascara_codigo_login" : "###.###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https"
	},
	{
		"codDistribuidor" : 5,
		"distribuidor" : "makena",
		"dominio_gestao" : "makena.inservice.app",
		"dominio_api" : "api.makena.inservice.app",
		"dominio_integracao" : "api.makena.inservice.app",
		"dominio_checklist" : "checklist.makena.inservice.app",
		"dominio_orcamento" : "ma.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.makena.inservice.app",
		"name_empresa" : "Makena",
		"link_loja_ios" : "https://apps.apple.com/br/app/makena-inservice-oferta/id1583089948",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=app.inservice.makena",
		"mascara_codigo_interno" : "00000",
		"mascara_codigo_login" : "###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https"
	},
	{
		"codDistribuidor" : 6,
		"distribuidor" : "interozone",
		"dominio_gestao" : "interozone.app",
		"dominio_api" : "api.interozone.app",
		"dominio_integracao" : "api.interozone.app",
		"dominio_checklist" : "checklist.interozone.app",
		"dominio_orcamento" : "in.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.interozone.app",
		"name_empresa" : "Interozone - Pescan",
		"link_loja_ios" : "https://apps.apple.com/br/app/interozone-e-pescan-oferta/id1581655942",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=app.interozone",
		"mascara_codigo_interno" : "00000",
		"mascara_codigo_login" : "###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https"
	},
	{
		"codDistribuidor" : 7,
		"distribuidor" : "prime",
		"dominio_gestao" : "prime.inservice.app",
		"dominio_api" : "api.prime.inservice.app",
		"dominio_integracao" : "api.prime.inservice.app",
		"dominio_checklist" : "checklist.prime.inservice.app",
		"dominio_orcamento" : "pr.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.prime.inservice.app",
		"name_empresa" : "Prime",
		"link_loja_ios" : "https://apps.apple.com/br/app/prime-inservice-oferta",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=app.inservice.prime",
		"mascara_codigo_interno" : "00000",
		"mascara_codigo_login" : "###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https",
		"theme": "makena"
	},
	{
		"codDistribuidor" : 8,
		"distribuidor" : "hml_interozone",
		"dominio_gestao" : "desenvolvimento.interozone.app",
		"dominio_api" : "api.desenvolvimento.interozone.app",
		"dominio_integracao" : "api.desenvolvimento.interozone.app",
		"dominio_checklist" : "checklist.desenvolvimento.interozone.app",
		"dominio_orcamento" : "dev.interozone.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.desenvolvimento.interozone.app",
		"name_empresa" : "HML - Interozone",
		"link_loja_ios" : "https://apps.apple.com/br/app/interozone-e-pescan-oferta/id1581655942",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=app.interozone",
		"mascara_codigo_interno" : "00000",
		"mascara_codigo_login" : "###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https",
		"theme": "interozone"
	},
	{
		"codDistribuidor" : 9,
		"distribuidor" : "hml_inservice",
		"dominio_gestao" : "desenvolvimento.inservice.app",
		"dominio_api" : "api.desenvolvimento.inservice.app",
		"dominio_integracao" : "api.desenvolvimento.inservice.app",
		"dominio_checklist" : "checklist.desenvolvimento.inservice.app",
		"dominio_orcamento" : "dev.inservice.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.desenvolvimento.inservice.app",
		"name_empresa" : "HML - Inservice",
		"link_loja_ios" : "https://apps.apple.com/br/app/makena-inservice-oferta/id1583089948",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=app.inservice.makena",
		"mascara_codigo_interno" : "00000",
		"mascara_codigo_login" : "###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https",
		"theme": "makena"
	},
	{
		"codDistribuidor" : 10,
		"distribuidor" : "homologacao",
		"dominio_gestao" : "localhost",
		"dominio_api" : "api.desenvolvimento.acampa.app",
		"dominio_integracao" : "api.desenvolvimento.acampa.app",
		"dominio_checklist" : "checklist.desenvolvimento.acampa.app",
		"dominio_orcamento" : "dev.acampa.orcamento.app",
		"dominio_pesquisa" : "dev-camp-pesquisa-api.azurewebsites.net",
		"name_empresa" : "HML",
		"link_loja_ios" : "https://apps.apple.com/us/app/acampa-oferta-digital-atria/id1451542707",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=br.com.atrialub.acampa",
		"mascara_codigo_interno" : "000000/00",
		"mascara_codigo_login" : "###.###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https"
	},
	{
		"codDistribuidor" : 11,
		"distribuidor" : "maxmuv",
		"dominio_gestao" : "maxmuv.inservice.app",
		"dominio_api" : "api.maxmuv.inservice.app",
		"dominio_integracao" : "api.maxmuv.inservice.app",
		"dominio_checklist" : "checklist.maxmuv.inservice.app",
		"dominio_orcamento" : "mv.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.maxmuv.inservice.app",
		"name_empresa" : "MaxMuv",
		"link_loja_ios" : "https://apps.apple.com/br/app/maxmuv-inservice-oferta",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=app.inservice.maxmuv.oferta",
		"mascara_codigo_interno" : "00000",
		"mascara_codigo_login" : "###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https",
		"theme": "makena"
	},
	{
		"codDistribuidor" : 12,
		"distribuidor" : "stellantis",
		"dominio_gestao" : "entregaimpecavel.app",
		"dominio_api" : "api.entregaimpecavel.app",
		"dominio_integracao" : "api.entregaimpecavel.app",
		"dominio_checklist" : "checklist.entregaimpecavel.app",
		"dominio_orcamento" : "st.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.entregaimpecavel.app",
		"name_empresa" : "Stellantis",
		"link_loja_ios" : "https://apps.apple.com/br/app/stellantis-oferta",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=app.stellantis.oferta",
		"mascara_codigo_interno" : "00000",
		"mascara_codigo_login" : "###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https"
	},
	{
		"codDistribuidor" : 13,
		"distribuidor" : "nihon",
		"dominio_gestao" : "nihon.app",
		"dominio_api" : "api.nihon.app",
		"dominio_integracao" : "api.nihon.app",
		"dominio_checklist" : "checklist.nihon.app",
		"dominio_orcamento" : "nh.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.nihon.app",
		"name_empresa" : "Nihon",
		"link_loja_ios" : "https://apps.apple.com/br/app/nihon-oferta",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=app.nihon.oferta",
		"mascara_codigo_interno" : "00000",
		"mascara_codigo_login" : "###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https"
	},
	{
		"codDistribuidor" : 14,
		"distribuidor" : "wurth",
		"dominio_gestao" : "acampa.app",
		"dominio_api" : "api.acampa.app",
		"dominio_integracao" : "integracao.acampa.app",
		"dominio_checklist" : "checklist.acampa.app",
		"dominio_orcamento" : "at.orcamento.app",
		"dominio_pesquisa" : "api-pesquisa.acampa.app",
		"name_empresa" : "Atria",
		"link_loja_ios" : "https://apps.apple.com/us/app/acampa-oferta-digital-atria/id1451542707",
		"link_loja_android" : "https://play.google.com/store/apps/details?id=br.com.atrialub.acampa",
		"mascara_codigo_interno" : "000000/00",
		"mascara_codigo_login" : "###.###.##",
		"integracao_dms" : "https://dms.camptecnologia.com.br/",
		"token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
		"tipo_conexao" : "https"
	},
];
export default distribuidorConfig;
